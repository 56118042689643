<template>
  <div class="imageUpload">
    <el-upload
      ref="upload"
      class="avatar-uploader"
      :action="$baseUpload"
      :headers="headers"
      :show-file-list="false"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeUpload"
      :on-error="errorUpload"
    >
      <div v-if="imgUrl" class="avatar">
        <img :src="$fileUrl + imgUrl" @click.stop="previewPicture">
        <i class="delete" @click.stop="removePicture" />
      </div>
      <div v-else class="uploader-bitmap">
        <img v-if="idCardFront" :src="idCardFront === 'facade' ? idCardZ : idCardF" alt="" class="id-card-img">
        <div class="avatar-uploader-icon">
          <i />
          <span>{{ explain }}</span>
        </div>
      </div>
    </el-upload>
    <el-dialog :visible.sync="dialogImageVisible" :close-on-click-modal="false">
      <img :src="$fileUrl + imgUrl" alt="文件图">
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    editImgUrl: {
      default: '',
      type: String
    },
    // 限制只能是图片  如果为true则只能是图片  为false则什么都可以的
    canOnlyPictures: {
      default: true,
      type: Boolean
    },
    // 身份证正反面
    idCardFront: {
      default: null,
      type: String
    },
    explain: {
      default: '',
      type: String
    },
    fileSize: {
      default: 10,
      type: Number
    }
  },
  data() {
    return {
      headers: {
        token: localStorage.getItem('logintoken')
      },
      imgUrl: '',
      idCardF: require('../assets/img/id_card_f.png'),
      idCardZ: require('../assets/img/id_card_z.png'),
      dialogImageVisible: false
    }
  },
  watch: {
    editImgUrl: {
      handler(newVal) {
        this.imgUrl = newVal
      }
    }
  },
  methods: {
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < this.fileSize
      const arr = file.name.split('.')
      if (!isLt2M) {
        this.$message.error(`文件大小不能超过 ${this.fileSize}MB!`)
        return
      }
      if (this.canOnlyPictures && !['jpg', 'png', 'gif', 'psd', 'tif', 'bmp'].includes(arr[arr.length - 1].toLowerCase())) {
        this.$message.error('上传的格式只能是jpg, png, gif, psd, tif, bmp')
        return false
      }
    },
    handleAvatarSuccess(res) {
      this.$refs.upload.clearFiles()
      if (res.code !== 200) {
        this.$message.warning('文件上传失败！')
        return
      }
      this.imgUrl = res.data
      this.$emit('getImgUrl', this.imgUrl)
    },
    // 预览图片
    previewPicture() {
      this.dialogImageVisible = true
    },
    removePicture() {
      this.imgUrl = ''
      this.$emit('getImgUrl', this.imgUrl)
    },
    // 上传失败提示
    errorUpload() {
      this.$message.warning('文件上传失败！')
    }
  }
}
</script>

<style scoped lang="scss">
.avatar-uploader {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  width: 228px;
  height: 126px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

/deep/ .el-upload {
  display: block;
  height: 100%;
}

.avatar-uploader:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  color: #585858;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  i {
    width: 39px;
    height: 39px;
    display: inline-block;
    background: url('../assets/img/file_icon.png') no-repeat top center;
    background-size: 100% 100%;
  }

  span {
    font-size: 12px;
    line-height: 12px;
  }
}

.avatar-uploader .avatar {
  width: 100%;
  height: 100%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }

  .delete {
    width: 16px;
    height: 16px;
    display: inline-block;
    background: url('../assets/img/delete.png') no-repeat top center;
    background-size: 100% 100%;
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0
  }
}

.uploader-bitmap {
  position: relative;
  height: 100%;
}

.id-card-img {
  width: 182px;
  height: 92px;
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
}

/deep/ .el-dialog {
  min-width: 1054px;
  min-height: 605px;
  background-color: #000000;
  display: flex;
  flex-direction: column;

  .el-dialog__body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  .el-dialog__headerbtn {
    width: 30px;
    height: 30px;
    background: rgba(51, 51, 51, 0.79);
    border-radius: 0px 4px 0px 4px;
    top: 0;
    right: 0;
    font-size: 20px;
    line-height: 30px;
  }

  .el-dialog__close {
    color: #fff;
  }

  img {
    max-width: 100%;
  }
}
</style>
